import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteForServiceWorker } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, NoddiFeedbackBox } from 'noddi-ui';
import { isToday, isTomorrow } from 'noddi-util';

import RouteCard from '../../components/ui/RouteCard';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';

const GroupedRoutes = ({
  routes,
  title,
  noRoutesText
}: {
  routes: RouteForServiceWorker[];
  title: string;
  noRoutesText?: string;
}) => {
  return (
    <>
      <p className='text-5 font-semibold'>{title}</p>
      {routes.length === 0 ? (
        <NoddiFeedbackBox size='small' variant='info' heading={noRoutesText} />
      ) : (
        routes.map((route) => (
          <div key={route.id} className='flex flex-col gap-4'>
            <RouteCard route={route} />
          </div>
        ))
      )}
    </>
  );
};

const MyRoutes = () => {
  const {
    data: routes,
    isPending: isRoutesLoading,
    isFetching: isRoutesFetching,
    error: routesError
  } = noddiAsync.useGet({
    type: URLKeys.getRoutesForServiceWorker
  });

  if (isRoutesLoading || isRoutesFetching) {
    return <LoadingScreen />;
  }
  if (routesError) {
    return <ErrorPage apiError={routesError} />;
  }

  // Group routes by today, tomorrow and rest
  const mappedRoutes = routes?.reduce(
    (acc, route) => {
      if (isToday(route.date)) {
        acc.today.push(route);
      } else if (isTomorrow(route.date)) {
        acc.tomorrow.push(route);
      } else {
        acc.rest.push(route);
      }
      return acc;
    },
    { today: [], tomorrow: [], rest: [] } as {
      today: RouteForServiceWorker[];
      tomorrow: RouteForServiceWorker[];
      rest: RouteForServiceWorker[];
    }
  );

  const todaysRoutesText = mappedRoutes.today.length ? "Today's route" : "Today's routes";

  const tomorrowsRoutesText = mappedRoutes.tomorrow.length === 1 ? "Tomorrow's route" : "Tomorrow's routes";

  return (
    <HomeContentWrapper hideBackButton>
      <div className='mb-10 mt-4 flex flex-col gap-2'>
        <GroupedRoutes
          title={todaysRoutesText}
          routes={mappedRoutes.today}
          noRoutesText='You have no routes for today'
        />

        <GroupedRoutes
          title={tomorrowsRoutesText}
          routes={mappedRoutes.tomorrow}
          noRoutesText='You currently have no assigned routes for tomorrow'
        />

        {mappedRoutes.rest.length > 0 && <GroupedRoutes routes={mappedRoutes.rest} title='Future routes' />}
      </div>
    </HomeContentWrapper>
  );
};

export default MyRoutes;
