import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserData, UserDataProps } from 'noddi-async/src/types';
import { UserRoleType, useAuthContext } from 'noddi-provider';
import { hasValidPermission } from 'noddi-provider/src/utils';
import { useState } from 'react';

import { commonTexts } from '../../../commonTexts';
import { errorCodes as errorCodesWithoutTranslation } from '../../../errorCodes';
import { NoddiButton } from '../../../molecules/NoddiButton';
import { ErrorCodes } from '../../Elements';
import { mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';

interface EmailLoginProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  requiredLoginLevels?: UserRoleType[];
  onLoginSuccess?: (userData: UserDataProps) => void;
  translations?: {
    emailAddress: string;
    password: string;
    logIn: string;
    accessDeniedLoginMessage: string;
  };
  errorCodes?: ErrorCodes;
  trackUserIdentity?: (userData: UserData) => void;
}

const EmailLogin = ({
  setErrorMessage,
  trackUserIdentity,
  requiredLoginLevels,
  onLoginSuccess,
  translations,
  errorCodes
}: EmailLoginProps) => {
  const { loginUser } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const texts = translations ?? {
    emailAddress: commonTexts.email,
    password: commonTexts.password,
    logIn: commonTexts.logIn,
    accessDeniedLoginMessage: commonTexts.accessDeniedLoginMessage
  };

  const errorCodeTexts = errorCodes ?? errorCodesWithoutTranslation;

  const { isPending, mutateAsync: login } = noddiAsync.usePost({
    type: URLKeys.postEmailLogin,
    queryConfig: {
      onSuccess: (res) => {
        const { user } = res.data;
        // Permission check
        if (requiredLoginLevels && !hasValidPermission(requiredLoginLevels, user)) {
          setErrorMessage(texts.accessDeniedLoginMessage);
          return;
        }
        // Log in user and reset form fields
        loginUser(res.data);
        trackUserIdentity?.(res.data.user);
        //optional callback for apps that needs to do specific things with user data on login success
        // and also to handle navigation
        onLoginSuccess?.(res.data);
      },
      onError: (error) => {
        setErrorMessage(mapErrorCodeToUiMessage({ error, errorCodes: errorCodeTexts }));
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        await login({ email, password });
      }}
    >
      <Stack spacing={3}>
        <TextField
          autoFocus
          fullWidth
          label={texts.emailAddress}
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label={texts.password}
          name='password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>
      <div className='mt-6'>
        <NoddiButton loading={isPending} disabled={isPending} type='submit' fullWidth>
          {texts.logIn}
        </NoddiButton>
      </div>
    </form>
  );
};

export { EmailLogin };
