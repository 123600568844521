import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import { AuthProvider, NoddiLocalizationProvider, useAuthContext } from 'noddi-provider';
import {
  APIBanner,
  Error404Page,
  ErrorPage,
  ForbiddenPage,
  NbFallback,
  NoddiCircularLoader,
  ToastProvider,
  createTheme
} from 'noddi-ui';
import queryString from 'query-string';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import routes from './appRoutes';
import HomePrivateLayout from './layouts/HomePrivateLayout';
import EditContactInfo from './pages/EditContactInfo';
import Login from './pages/Login';
import MyFeedback from './pages/MyFeedback';
import MyRoutes from './pages/MyRoutes';
import { PickingListWorkerApp } from './pages/PickingList';
import Profile from './pages/Profile.tsx';
import ServiceWorkerRoute from './pages/ServiceWorkerRoute';
import RouteItemOnRoute from './pages/ServiceWorkerRoute/RouteItemOnRoute';
import CarWheelSet from './pages/Storage/CarWheelSet';
import WorkPreferences from './pages/WorkPreferences';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const theme = createTheme();

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary fallback={<NbFallback />}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify
          }}
        >
          <NoddiServerContext>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <ToastProvider defaultSize='small'>
                  <NoddiLocalizationProvider>
                    <Router />
                    <APIBanner />
                  </NoddiLocalizationProvider>
                </ToastProvider>
              </ThemeProvider>
            </AuthProvider>
          </NoddiServerContext>
        </QueryParamProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoadingOnMount } = useAuthContext();

  if (isTokenLoadingOnMount) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }

  return (
    <SentryRoutes>
      <Route path={routes.login.getBasePath()} element={<Login />} />
      <Route path={routes.error.getBasePath()} element={<Error404Page />} />
      <Route path='*' element={<Error404Page />} />
      <Route path={routes.forbiddenPage.getBasePath()} element={<ForbiddenPage />} />

      <Route element={<HomePrivateLayout />}>
        <Route path={routes.home.getBasePath()} element={<MyRoutes />} />
        <Route path={routes.profile.getBasePath()} element={<Profile />} />
        <Route path={routes.editContactInfo.getBasePath()} element={<EditContactInfo />} />
        <Route path={routes.calender.getBasePath()} element={<WorkPreferences />} />
        <Route path={routes.routes.getBasePath()} element={<MyRoutes />} />
        <Route path={routes.routeItemOnRoute.getBasePath()} element={<RouteItemOnRoute />} />
        <Route path={routes.feedback.getBasePath()} element={<MyFeedback />} />
        <Route path={routes.carWheelSet.getBasePath()} element={<CarWheelSet />} />
        <Route path={routes.pickingList.getBasePath()} element={<PickingListWorkerApp />} />
        <Route path={routes.todaysAppointments.getBasePath()} element={<ServiceWorkerRoute />} />
      </Route>
    </SentryRoutes>
  );
};

export default App;
