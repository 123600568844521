import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteSettlementPayment } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, NoddiDialog, NoddiTable, usePagination } from 'noddi-ui';
import { DateFormats, format, formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { Payment } from './PaymentSection';

const MyPayments = () => {
  const { userData } = useAuthContext();
  const { page, setPage, pageSize, setPageSize } = usePagination();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<RouteSettlementPayment>();

  const { data: routeSettlements, isPending: isRouteSettlementsLoading } = noddiAsync.useGet({
    type: URLKeys.getRouteSettlementsForWorker,
    input: {
      page,
      pageSize,
      userId: String(userData?.user.id),
      type: 1
    },
    queryConfig: { enabled: !!userData?.user.id }
  });

  if (!routeSettlements && !isRouteSettlementsLoading) {
    return <ErrorPage />;
  }

  const tableItems = routeSettlements?.results.map((item) => {
    return {
      renderItems: [
        format(new Date(item.route.date), DateFormats.DASHED_DATE_ISO_8601),
        `${formatCurrencyAmount(parseInt(item.settledPayment ?? '0') + parseInt(item.extraPayment ?? '0'))}`,
        item.paidAt ? `paid out ${format(new Date(item.paidAt), DateFormats.DASHED_DATE_ISO_8601)}` : 'pending'
      ],
      item
    };
  });

  return (
    <div className='flex flex-col gap-2'>
      <p className='font-semibold'>My payments</p>
      <div className='rounded-lg bg-primary-white px-1 py-5 xxs:px-3'>
        <NoddiTable
          wrapWithCard={false}
          count={routeSettlements?.count}
          items={tableItems}
          isLoading={isRouteSettlementsLoading}
          onRowClick={(item) => {
            setIsDialogOpen(true);
            setSelectedItem(item);
          }}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          headers={['Route Date', 'Amount', 'Payment status']}
          dontCollapseOnMobile
        />
      </div>
      {selectedItem && (
        <NoddiDialog title='Payment details 💰' onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
          <Payment routeSettlement={selectedItem} />
        </NoddiDialog>
      )}
    </div>
  );
};

export default MyPayments;
